<template>
  <div>
    <multiselect
      v-model="store"
      :options="async (q) => loadStoreOptions(q, store)"
      :loading="loadingStoreOptions"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
    <fancy-input v-model="start" type="datetime-local" :label="$t('revenueStatistics.startDate')" />
    <fancy-input v-model="end" type="datetime-local" :label="$t('revenueStatistics.endDate')" />
    <simple-dropdown v-model="groupFormat" :label="$t('revenueStatistics.groupFormat.label')">
      <option v-for="(data, key) in groupFormats" :key="key" :value="data">
        {{ $t(`revenueStatistics.groupFormat.${key}`) }}
      </option>
    </simple-dropdown>
    <multiselect
      v-model="customerGroup"
      :options="async (q) => loadCustomerGroupOptions(q, customerGroup)"
      :loading="loadingCustomerGroupOptions"
      :form-label="$t('customerGroup.label')"
      :filter-results="false"
      searchable
    />
    <button class="btn mt-5" @click="fetchData">
      {{ $t('overallStatistics.fetch') }}
    </button>
    <hr>
    <div v-if="statsData">
      <h2>{{ $t('overallStatistics.netResults') }}</h2>
      <statistics-table
        :net="true"
        :group-format="groupFormat"
        :discounts="discounts"
        :stats-data="statsData"
        :vats="vats"
        :time-slots="timeSlots"
        :currencies="currencies"
      />
      <h2 class="mt-5">
        {{ $t('overallStatistics.grossResults') }}
      </h2>
      <statistics-table
        :net="false"
        :group-format="groupFormat"
        :discounts="discounts"
        :stats-data="statsData"
        :vats="vats"
        :time-slots="timeSlots"
        :currencies="currencies"
      />
      <div v-if="!store.strict">
        <h2>{{ $t('overallStatistics.rawData') }}</h2>
        <pre>
          {{ statsData }}
        </pre>
      </div>
    </div>
  </div>
</template>
<script>

import { ref } from 'vue';
import {
  subDays, format, formatISO, parse, startOfMonth,
} from 'date-fns';
import useLoadOptions from '@/hooks/api/loadOptions';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

import axios from '@/services/axios';
import formatPrice from '@/utils/formatPrice';
import getIdFromIri from '@/utils/getIdFromIri';
import StatisticsTable from '@/components/pages/statistics/overall_statistics/statisticsTable.vue';

export default {
  name: 'OverallStatisticsIndexPage',
  components: {
    StatisticsTable,
    Multiselect,
    FancyInput,
    SimpleDropdown,
  },
  setup() {
    const store = ref(null);
    const customerGroup = ref(null);
    const start = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd\'T\'HH:mm'));
    const end = ref(format(subDays(new Date(), 1).setHours(23, 59, 59), 'yyyy-MM-dd\'T\'HH:mm'));
    const vats = ref([]);
    const timeSlots = ref([]);
    const statsData = ref(null);
    const currencies = ref([]);
    const discounts = ref({});

    const groupFormats = {
      second: {
        backend: '%Y-%m-%dT%H:%i:%s+0100',
        chart: 'yyyy-MM-dd\'T\'HH:mm:ssx',
        display: 'yyyy-MM-dd\'T\'HH:mm:ss',
      },
      minute: {
        backend: '%Y-%m-%dT%H:%i+0100',
        chart: 'yyyy-MM-dd\'T\'HH:mmx',
        display: 'yyyy-MM-dd\'T\'HH:mm',
      },
      hour: {
        backend: '%Y-%m-%dT%H+0100',
        chart: 'yyyy-MM-dd\'T\'HHx',
        display: 'yyyy-MM-dd\'T\'HH',
      },
      day: {
        backend: '%Y-%m-%d+0100',
        chart: 'yyyy-MM-ddx',
        display: 'yyyy-MM-dd',
      },
      week: {
        backend: '%x-%v+0100',
        chart: 'RRRR-IIx',
        display: 'RRRR-II',
      },
      month: {
        backend: '%Y-%m+0100',
        chart: 'yyyy-MMx',
        display: 'yyyy-MM',
      },
      year: {
        backend: '%Y+0100',
        chart: 'yyyyx',
        display: 'yyyy',
      },
    };
    const groupFormat = ref(groupFormats.month);
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    const {
      loadOptions: loadCustomerGroupOptions,
      loading: loadingCustomerGroupOptions,
    } = useLoadOptions('/customer_groups', 'name');

    const fetchData = async () => {
      const { data } = await axios.post('/statistics/advanced', {
        format: groupFormat.value.backend,
        start: start.value,
        end: end.value,
        store: store.value,
        customerGroup: customerGroup.value,
      });

      timeSlots.value = [];
      currencies.value = [];
      vats.value = [];
      const discountIds = [];

      Object.keys(data).forEach((s) => {
        timeSlots.value.push(s);
        const cs = data[s];
        Object.keys(cs).forEach((c) => {
          if (!currencies.value.includes(c)) {
            currencies.value.push(c);
          }
          const vs = data[s][c].perVat;
          let totNetDiscounted = 0;
          let totDiscounted = 0;
          let totNetDiscountedRefund = 0;
          let totDiscountedRefund = 0;
          let totNetUndiscounted = 0;
          let totUndiscounted = 0;
          let totNetUndiscountedRefund = 0;
          let totUndiscountedRefund = 0;
          const totNetDiscounts = {};
          const totDiscounts = {};
          Object.keys(vs).forEach((v) => {
            if (!vats.value.includes(v)) {
              vats.value.push(v);
            }
            totNetDiscounted += data[s][c].perVat[v].netDiscountedRevenue ?? 0;
            totDiscounted += data[s][c].perVat[v].discountedRevenue ?? 0;
            totNetDiscountedRefund += data[s][c].perVat[v].netDiscountedRefunds ?? 0;
            totDiscountedRefund += data[s][c].perVat[v].discountedRefunds ?? 0;
            totNetUndiscounted += data[s][c].perVat[v].netUndiscountedRevenue ?? 0;
            totUndiscounted += data[s][c].perVat[v].undiscountedRevenue ?? 0;
            totNetUndiscountedRefund += data[s][c].perVat[v].netUndiscountedRefunds ?? 0;
            totUndiscountedRefund += data[s][c].perVat[v].undiscountedRefunds ?? 0;
            Object.keys(data[s][c].perVat[v].netDiscountAmounts).forEach((did) => {
              discountIds.push(did);
              if (!Object.prototype.hasOwnProperty.call(totNetDiscounts, did)) {
                totNetDiscounts[did] = 0;
                totDiscounts[did] = 0;
              }
              totNetDiscounts[did] += data[s][c].perVat[v].netDiscountAmounts[did] ?? 0;
              totDiscounts[did] += data[s][c].perVat[v].discountAmounts[did] ?? 0;
            });
          });
          data[s][c].totals = {
            netDiscountedRevenue: totNetDiscounted,
            discountedRevenue: totDiscounted,
            netDiscountedRefunds: totNetDiscountedRefund,
            discountedRefunds: totDiscountedRefund,
            netUndiscountedRevenue: totNetUndiscounted,
            undiscountedRevenue: totUndiscounted,
            netUndiscountedRefunds: totNetUndiscountedRefund,
            undiscountedRefunds: totUndiscountedRefund,
            netDiscountAmounts: totNetDiscounts,
            discountAmounts: totDiscounts,
          };
        });
      });

      timeSlots.value = timeSlots.value.sort();
      statsData.value = data;

      if (discountIds.length > 0) {
        const { data: discountData } = await axios.get('/discounts', {
          params: {
            id: [...new Set(discountIds.map((i) => getIdFromIri(i)))],
            pagination: false,
          },
        });
        discounts.value = {};
        discountData['hydra:member'].forEach((discount) => {
          discounts.value[discount.id] = discount;
        });
      }
    };

    return {
      loadStoreOptions,
      loadingStoreOptions,
      store,
      loadCustomerGroupOptions,
      loadingCustomerGroupOptions,
      customerGroup,
      start,
      end,
      groupFormat,
      groupFormats,
      fetchData,
      vats,
      timeSlots,
      statsData,
      currencies,
      formatPrice,
      parse,
      format,
      discounts,
    };
  },
  computed: {
    startDate() {
      return formatISO(new Date(this.start));
    },
    endDate() {
      return formatISO(new Date(this.end));
    },
  },
};

</script>
<style lang="scss" scoped>
table {
  tr {
    &:hover {
      background-color: inherit;
    }
    &:nth-of-type(even):hover {
      background-color: var(--color-highlight);
    }
    td, th {
      padding: 1rem;
    }
    &:nth-child(3n) {
      border-bottom: 2px solid #000;
    }
  }
}
</style>
