/* eslint-disable max-len */
/**
 * Some notes to keep this file consistent:
 * * Top-Level-Keys should be either "general", "pages" or the name of an entity
 * * "label" should always be pluralized
 */
export default {
  // General/shared keys
  general: {
    action: {
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      create: 'Erfassen',
      createSth: '{0} @.lower:general.action.create',
      delete: 'Löschen',
      deleteSth: '{0} @.lower:general.action.delete',
      dropFile: 'Datei hierher ziehen...',
      edit: 'Bearbeiten',
      editSth: '{0} @.lower:general.action.edit',
      label: 'Aktion|Aktionen',
      login: 'Login',
      remove: 'Entfernen',
      removeSth: '{0} entfernen',
      save: 'Speichern',
      saveSth: '{0} @.lower:general.action.save',
      showCustomers: 'Kunden',
      ok: 'OK',
      createMultiple: 'Mehrere Erstellen',
      back: 'Zurück',
      add: 'Hinzufügen',
      reset: 'Zurücksetzen',
    },
    datetime: {
      monday: 'Montag',
      tuesday: 'Dienstag',
      wednesday: 'Mittwoch',
      thursday: 'Donnerstag',
      friday: 'Freitag',
      saturday: 'Samstag',
      sunday: 'Sonntag',
      clock: 'Uhr',
    },
    deleteQuestion: 'Bist du sicher, dass {0} {1} gelöscht werden soll?',
    deleteWarning: 'Gelöschte {0} sind unwiderruflich entfernt!',
    field: {
      amount: 'Anzahl',
      description: 'Beschreibung',
      end: 'Ende',
      ipAddress: 'IP-Adresse',
      name: 'Name',
      start: 'Start',
      text: 'Text',
      title: 'Titel',
      value: 'Wert',
      sort: 'Sortierung',
      language: 'Sprache',
    },
    form: {
      noResults: 'Keine Ergebnnise',
      typeToSearch: 'Tippe etwas um zu suchen.',
    },
    none: 'Keine',
    configuration: 'Konfiguration',
    languages: {
      de: 'Deutsch',
      en: 'Englisch',
    },
  },
  error: {
    fourCharacters: '{0} muss genau vier Zeichen lang sein.',
    invalidButtonType: 'Ungültiger Button-Typ',
    invalidDiscountType: 'Ungültiger Discount-Typ',
    invalidIdentifierType: 'Ungültiger Identifier-Typ',
    invalidIpAddress: 'Ungültige IP-Adresse',
    invalidLineItemType: '{0} ist ungültig',
    invalidPaymentMethod: 'Ungültige Zahlungsmethode',
    invalidPricingMode: 'Ungültiger Bezahl-Modus',
    invalidVatType: 'Ungültiger MwSt.-Satz',
    noCurrency: '{0} ist keine richtige Währung',
    notBlank: '{0} darf nicht leer sein.',
    notNumber: '{0} ist keine Nummber',
    thisOrRegisterNotBlank: '{0} oder Kasse muss ausgewählt werden',
    thisOrProductGroupNotBlank: '{0} oder Produktgruppe muss ausgewählt werden',
  },
  // Page-specific keys
  pages: {
    discounts: { title: 'Rabatte und Promotionen' },
    hardware: {
      title: 'Hardware',
    },
    index: {
      title: 'Willkommen im Adminbereich!',
    },
    login: {
      error: 'Fehler beim Einloggen',
      title: 'Login',
      loginSuccess: 'Erfolgreich eingeloggt!',
      logoutSuccess: 'Erfolgreich ausgeloggt!',
    },
    passwordReset: {
      request: {
        title: 'Neues Passwort anfordern',
        success: 'Wenn es ein Konto mit dieser Adresse gibt, haben wir eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passworts an diese Adresse geschickt.',
        error: 'Fehler beim Zurücksetzen des Passworts.',
      },
      reset: {
        title: 'Passwort zurücksetzen',
        submit: 'Passwort ändern',
        success: 'Ihr Passwort wurde geändert. Sie können sich jetzt mit dem neuen Passwort anmelden.',
        error: 'Fehler beim Ändern des Passworts.',
      },
    },
    statistics: {
      title: 'Statistiken',
    },
  },

  // Entity-specific keys
  baseWeight: {
    gramm: { label: 'Gramm' },
    label: 'Tara-Gewicht|Tara-Gewichte',
    labelWithArticle: 'das Tara-Gewicht|die Tara-Gewichte',
    weight: {
      label: 'Gewicht',
    },
  },
  button: {
    icon: { label: 'Icon|Icons' },
    label: 'Button|Buttons',
    labelWithArticle: 'der Button|die Buttons',
    type: {
      label: 'Typ|Typen',
      BUTTON_TYPE_EMPTY: 'Text',
      BUTTON_TYPE_PRODUCT: 'Produkt',
      BUTTON_TYPE_GROUP: 'Produktgruppe',
      BUTTON_TYPE_REGISTER_LAYOUT: 'Kassenlayout',
    },
    backgroundColor: { label: 'Hintergrundfarbe' },
    foregroundColor: { label: 'Vordergrundfarbe' },
    fontSize: { label: 'Schriftgrösse' },
  },
  company: {
    label: 'Firma|Firmen',
    labelWithArticle: 'die Firma|die Firmen',
  },
  customer: {
    balance: { label: 'Guthaben' },
    sort: { label: 'Sortierung für manuelle Kundenwahl' },
    batch: {
      createTitle: 'Mehrere Kunden',
    },
    label: 'Kunde|Kunden',
    labelWithArticle: 'der Kunde|die Kunden',
    number: {
      label: 'Kundennummer|Kundennummern',
      start: 'Kundennummer Start',
    },
    showAll: 'Alle Kunden anzeigen',
    orderList: 'Bestellungen',
    orders: {
      balanceUsed: 'Guthaben verwendet',
      balanceRecharged: 'Guthaben aufgeladen',
    },
  },
  customerGroup: {
    batchCreate: 'Erstelle Kunden in Kundengruppe',
    label: 'Kundengruppe|Kundengruppen',
    labelWithArticle: 'die Kundengruppe|die Kundengruppen',
    number: { label: 'Nummer|Nummern' },
    manual: { label: 'Kundengruppe manuell auswählbar|Kundengruppen manuell auswählbar' },
    action: {
      export: 'Kundengruppe exportieren',
      startExport: 'Export starten',
    },
    export: {
      type: {
        label: 'Export-Typ',
        TYPE_QR: 'QR-Codes',
      },
      maxNumber: {
        label: 'Max. Kundennummer',
      },
      minNumber: {
        label: 'Min. Kundennummer',
      },
      limit: {
        label: 'Limit',
      },
      customCss: {
        label: 'Custom CSS',
      },
    },
  },
  customerIdentifier: {
    identifier: {
      label: 'ID|IDs',
    },
    label: 'Kunden-ID|Kunden-IDs',
    labelWithArticle: 'die Kunden-ID|die Kunden-IDs',
    createRandom: 'Zufällig generieren',
    xIdentifierFound: '{0} Kunden-IDs gefunden',
    updateTitle: 'Kunden-IDs aktualisieren',
    removeTitle: 'Kunden-IDs löschen',
    type: {
      label: 'Typ|Typen',
      10: 'QR-Code',
      20: 'Evis',
    },
  },
  discount: {
    showAll: 'Alle Rabatte anzeigen',
    absoluteDiscount: { label: 'Absoluter Rabatt' },
    applyToOrder: { label: 'Gilt für Gesamtbestellung' },
    applyToSubProducts: { label: 'Auf Unterprodukte anwenden' },
    cashierSelectable: { label: 'An bedienter Kasse auswählbar' },
    label: 'Rabatt|Rabatte',
    labelWithArticle: 'der Rabatt|die Rabatte',
    percentDiscount: { label: 'Relativer Rabatt' },
    type: {
      label: 'Typ|Typen',
      10: 'relativ',
      20: 'absolut',
    },
  },
  icon: {
    label: 'Icon|Icons',
  },
  image: {
    label: 'Bild|Bilder',
    labelWithArticle: 'das Bild|die Bilder',
  },
  price: {
    label: 'Preis|Preise',
    labelWithArticle: 'der Preis|die Preise',
  },
  printer: {
    label: 'Drucker|Drucker',
    labelWithArticle: 'der Drucker|die Drucker',
    receipt: {
      label: 'Belegdrucker|Belegducker',
    },
    preorder: {
      label: 'Belegdrucker für Mitarbeiter|Belegducker für Mitarbeiter',
    },
    model: {
      label: 'Modell',
    },
  },
  printerRule: {
    label: 'Druckregel|Druckregeln',
    labelWithArticle: 'die Druckregel|die Druckregeln',
    blockRegisterOnFail: 'Bei Fehler Kasse blockieren',
  },
  product: {
    label: 'Produkt|Produkte',
    labelWithArticle: 'das Produkt|die Produkte',
    number: { label: 'Nummer|Nummern' },
    pricingMode: {
      PRICING_MODE_FIX: 'Fixpreis',
      PRICING_MODE_WEIGHT: 'Gewicht',
      PRICING_MODE_VARIABLE: 'Variabel',
      label: 'Zahlung pro|Zahlung pro',
    },
    productType: {
      label: 'Typ',
      PRODUCT_TYPE_NORMAL: 'Normal',
      PRODUCT_TYPE_RECHARGE_BALANCE: 'Guthaben aufladen',
    },
    productNumber: {
      label: 'Produktnummer|Produktnummern',
    },
    showAll: 'Alle Produkte anzeigen',
  },
  productGroup: {
    label: 'Produktgruppe|Produktgruppen',
    labelWithArticle: 'die Produktgruppe|die Produktgruppen',
    deleteWarning: 'Achtung: Alle Produkte, die in dieser Produktgruppe enthalten sind, werden auch gelöscht! Ebenso Buttons in Kassenlayouts, die auf diese Produkte zeigen',
    addAsSubProduct: { label: 'Als Unterprodukt auf letztem Produkt hinzufügen' },
  },
  productNoteTemplate: {
    label: 'Notizvorlage|Notizvorlagen',
  },
  promotion: {
    availability: {
      availableOn: 'Verfügbar am',
      day: 'Tag',
      end: 'Bis',
      label: 'Verfügbarkeit|Verfügbarkeiten',
      start: 'Ab',
    },
    oncePerDay: 'Nur 1x täglich einlösbar',
    label: 'Promotion|Promotionen',
    labelWithArticle: 'die Promotion|die Promotionen',
    minPrice: { label: 'Mindesteinkauf' },
  },
  register: {
    all: 'Alle Kassen',
    lastHeartbeat: { label: 'Last Heartbeat|Last Hearbeat' },
    lastSync: { label: 'Last Sync|Last Sync' },
    label: 'Kasse|Kassen',
    labelWithArticle: 'die Kasse|die Kassen',
    selfCheckout: { label: 'Self-Checkout|Self-Checkouts' },
    allowPreorder: { label: 'Vorbestellungen erlauben' },
    invoiceIdentifier: { label: 'Rechnungsnummer-Präfix' },
    orderCalloutNumberRangeStart: { label: 'Callout-Range Start' },
    orderCalloutNumberRangeEnd: { label: 'Callout-Range Ende' },
    customerReceiptOptional: { label: 'Belegdruck freiwillig' },
    customerReceiptPrintMode: {
      label: 'Kundenbeleg drucken',
      RECEIPT_PRINT_ALWAYS: 'Immer',
      RECEIPT_PRINT_ASK: 'Fragen',
      RECEIPT_PRINT_NEVER: 'Nie',
    },
    onboarding: {
      title: 'Kasse onboarden',
      warning: 'Falls bereits eine Kasse angemeldet ist, wird diese ausgeloggt.',
      confirm: 'Bestätigen',
    },
    action: {
      onboard: 'Onboarden',
    },
  },
  registerLayout: {
    col: { label: 'Spalte|Spalten' },
    label: 'Kassenlayout|Kassenlayouts',
    labelWithArticle: 'das Kassenlayout|die Kassenlayouts',
    row: { label: 'Zeile|Zeilen' },
  },
  sidebarMenu: {
    label: 'QuickAccess-Menü|QuickAccess-Menüs',
  },
  sidebarMenuEntry: {
    label: 'QuickAccess-Menüpunkt|QuickAccess-Menüpunkte',
  },
  registerTheme: {
    color: {
      label: 'Farbe|Farben',
      color1: 'Farbe 1|Farben 1',
      color2: 'Farbe 2|Farben 2',
      color3: 'Farbe 3|Farben 3',
      color4: 'Farbe 4|Farben 4',
      color5: 'Farbe 5|Farben 5',
      color6: 'Farbe 6|Farben 6',
      color7: 'Farbe 7|Farben 7',
      color8: 'Farbe 8|Farben 8',
      splashScreenBackground: 'Splash Screen Hintergrund Farbe',
      splashScreenTileText: 'Splash Screen Kachel Text Farbe',
      splashScreenTitleText: 'Splash Screen Titel Text Farbe',
      splashScreenTileBackground: 'Splash Screen Kachel Hintergrund Farbe',
      splashScreenTileBorder: 'Splash Screen Kachel Rahmen Farbe',
      correctionButtonBackground: 'Korrektur Button Hintergrund Farbe',
      correctionButtonText: 'Korrektur Button Text Farbe',
      correctionButtonActiveBackground: 'Aktiv Korrektur Button Hintergrund Farbe',
      correctionButtonActiveText: 'Aktiv Korrektur Button Text Farbe',
      sidebarBackground: 'Sidebar Hintergrund Farbe',
      separatorLineColor: 'Separator Linie Farbe',
      sidebarText: 'Sidebar Text Farbe',
      sidebarPlusButtonBackground: 'Sidebar Plus Button Hintergrund Farbe',
      sidebarMinusButtonBackground: 'Sidebar Minus Button Hintergrund Farbe',
      sidebarMinusInactiveButtonBackground: 'Sidebar Inaktiv Minus Button Farbe',
      sidebarDeleteButtonBackground: 'Sidebar Löschen Button Hintergrund Farbe',
      sidebarPlusButtonText: 'Sidebar Plus Button Text Farbe',
      sidebarMinusButtonText: 'Sidebar Minus Button Text Farbe',
      sidebarMinusInactiveButtonText: 'Sidebar Inaktiv Minus Button Text Farbe',
      sidebarDeleteButtonText: 'Sidebar Löschen Button Text Farbe',
      payButtonBackground: 'Bezahlen Button Hintergrund Farbe',
      payButtonInactiveBackground: 'Inaktiv Bezahlen Button Hintergrund Farbe',
      payButtonText: 'Bezahlen Button Text Farbe',
      payButtonInactiveText: 'Inaktiv Bezahlen Button Text Farbe',
      promoButtonBackground: 'Promo Button Hintergrund Farbe',
      promoButtonText: 'Promo Button Text Farbe',
      mainBackground: 'Haupt Hintergrund Farbe',
      navigationButtonBackground: 'Navigation Button Hintergrund Farbe',
      navigationButtonText: 'Navigation Button Text Farbe',
      currentLocationText: 'Aktuelle Position Text Farbe',
      tilesBackground: 'Kacheln Hintergrund Farbe',
      tilesText: 'Kacheln Text Farbe',
      tilesImage: 'Kacheln Bild Farbe',
      productModificationBackground: 'Produkt Anpassen Hintergrund Farbe',
      productModificationTitleText: 'Produkt Anpassen Titel Text Farbe',
      productModificationPriceText: 'Produkt Anpassen Preis Text Farbe',
      modificationCloseButtonBackground: 'Anpassen Schliessen Button Hintergrund Farbe',
      modificationCloseButtonText: 'Anpassen Schliessen Button Text Farbe',
      modifierTitleText: 'Modifikator Titel Text Farbe',
      modifierSelectionBackground: 'Modifikator',
      selectedModifier: 'Ausgewählter Modifikator Farbe',
      nonselectedModifier: 'Nicht Ausgewählter Modifikator Farbe',
      mandatoryModifierText: 'Pflicht Modifikator Text Farbe',
      mandatoryModifierSelectedText: 'Pflicht Modifikator Ausgewählt Text Farbe',
      optionalModifierText: 'Optionaler Modifikator Text Farbe',
      optionalModifierDisabledText: 'Deaktivierter Optionaler Modifikator Text Farbe',
      modifierIncreaseButtonBackground: 'Modifikator erhöhen Button Hintergrund Farbe',
      modifierIncreaseInactiveButtonBackground: 'Inaktiver Modifikator erhöhen Button Hintergrund Farbe',
      modifierDecreaseButtonBackground: 'Modifikator verringern Button Hintergrund Farbe',
      modifierDecreaseInactiveButtonBackground: 'Inaktiver Modifikator verringern Button Hintergrund Farbe',
      modifierIncreaseButtonText: 'Modifikator erhöhen Button Text Farbe',
      modifierIncreaseInactiveButtonText: 'Inaktiver Modifikator erhöhen Button Text Farbe',
      modifierDecreaseButtonText: 'Modifikator verringern Button Text Farbe',
      modifierDecreaseInactiveButtonText: 'Inaktiver Modifikator verringern Button Text Farbe',
      modifierAddButtonBackground: 'Modifikator hinzufügen Button Hintergrund Button',
      modifierAddButtonText: 'Modifikator hinzufügen Button Text Farbe',
      weightDetailBackground: 'Gewicht Detail Hintergrund Farbe',
      weightDetailTitleText: 'Gewicht Detail Titel Farbe',
      weightDetailSubtitleText: 'Gewicht Detail Untertitel Text Farbe',
      weightDetailAmountText: 'Gewicht Detail Betrag Text Farbe',
      weightDetailErrorText: 'Gewicht Detail Fehler Text Farbe',
      weightDetailPriceText: 'Gewicht Detail Preis Text Farbe',
      weightSelectionBackground: 'Gewicht Auswahl Hintergrund Farbe',
      weightSelectionTileBackground: 'Gewicht Auswahl Kachel Hintergrund Farbe',
      weightSelectionTileText: 'Gewicht Auswahl Kachel Text Farbe',
      weightSelectionTileSelectedBackground: 'Ausgewähltes Gewicht Auswahl Kachel Hintergrund Farbe',
      weightSelectionTileSelectedText: 'Ausgewähltes Gewicht Auswahl Kachel Text Farbe',
      weightSelectionScaleImage: 'Gewicht Auswahl Waage Bild Farbe ',
      weightCloseButtonBackground: 'Gewicht Schliessen Button Hintergrund Farbe',
      weightCloseButtonText: 'Gewicht schliessen Button Text Farbe',
      weightAddButtonBackground: 'Gewicht hinzufügen Button Hintergrund Farbe',
      weightAddButtonText: 'Gewicht hinzufügen Text Farbe',
      weightAddButtonDisabledBackground: 'Inaktiver Gewicht hinzufügen Button Hintergrund Farbe',
      weightAddButtonDisabledText: 'Inaktiver Gewicht hinzufügen Button Text Farbe',
      qrScannerBackground: 'QR-Scanner Hintergrund Farbe',
      qrScannerTitleText: 'QR-Scanner Titel Text Farbe',
      qrScannerSubtitleText: 'QR-Scanner Untertitel Text Farbe',
      qrScannerCloseButtonBackground: 'QR-Scanner schliessen Button Hintergrund Farbe',
      qrScannerCloseButtonText: 'QR-Scanner Schliessen Button Text Farbe',
      clearAlertBackground: 'Löschen Alarm Hintergrund Farbe',
      clearAlertTitle: 'Löschen Alarm Titel Farbe',
      clearAlertMessage: 'Löschen Alarm Nachricht Farbe',
      clearAlertXButtonBackground: 'Löschen Alarm X Button Hintergrund Farbe',
      clearAlertXButtonForeground: 'Löschen Alarm X Button Vordergrund Farbe',
      clearAlertAbortButtonBackground: 'Löschen Alarm abbrechen Button Hintergrund Farbe',
      clearAlertAbortButtonText: 'Löschen Alarm abbrechen Button Text Farbe',
      clearAlertCloseButtonBackground: 'Löschen Alarm schliessen Button Hintergrund Farbe',
      clearAlertCloseButtonText: 'Löschen Alarm Schliessen Button Text Farbe',
      defaultTilesBottomColor: 'Produktkachel Linie Standardfarbe',
      navigationBarBackground: 'Navigationsbar Hintergrund Farbe',
      navigationBarMenuActiveText: 'Navigationsbar Text Aktiv Farbe',
      navigationBarMenuInactiveText: 'Navigationsbar Text Inaktiv Farbe',
      submenuBackground: 'Untermenü Hintergrund Farbe',
      submenuSelectionText: 'Untermenü Aktiv Farbe',
      submenuInactiveText: 'Untermenü Inaktiv Farbe',
      submenuSeparatorLine: 'Untermenü Trennlinie Farbe',
      submenuActiveLine: 'Untermenü Aktiv Linie Farbe',
      switchBackground: 'Rechnungs-Switcher Hintergrund Farbe',
      switchInactiveText: 'Rechnungs-Switcher Inaktiv Text Farbe',
      switchActiveText: 'Rechnungs-Switcher Aktiv Text Farbe',
      switchActiveBackground: 'Rechnungs-Switcher Aktiv Hintergrund Farbe',
      servedTableSelectionBackground: 'Tischauswahl Hintergrund Farbe',
      servedActiveTable: 'Tischauswahl Aktiver Tisch Hintergrund Farbe',
      servedActiveTableText: 'Tischauswahl Aktiver Tisch Text Farbe',
      servedInactivetiveTable: 'Tischauswahl Inaktiver Tisch Hintergrund Farbe',
      servedInactivetiveTableText: 'Tischauswahl Inaktiver Tisch Text Farbe',
      servedTableBorderColor: 'Tischauswahl Tisch Border Farbe',
      servedTableShadowColor: 'Tischauswahl Tisch Schatten Farbe',
      servedSelectedTableBackground: 'Bestellübersicht Hintergrund Farbe',
      servedSelectedTableTitle: 'Bestellübersicht Titel Farbe',
      tableOrderActiveBackground: 'Bestellübersicht Aktive Bestellung Hintergrund Farbe',
      tableOrderActiveTitle: 'Bestellübersicht Aktive Bestellung Titel Farbe',
      tableOrderInactiveBackground: 'Bestellübersicht Alte Bestellung Hintergrund Farbe',
      tableOrderInactiveTitle: 'Bestellübersicht Alte Bestellung Titel Farbe',
      tableOrderProductAmountBackground: 'Bestellübersicht Produktanzahl Hintergrund Farbe',
      tableOrderProductAmount: 'Bestellübersicht Produktanzahl Text Farbe',
      tableOrderProductName: 'Bestellübersicht Produkt Name Farbe',
      tableOrderProductPrice: 'Bestellübersicht Produkt Preis Farbe',
      tableOrderProductDiscount: 'Bestellübersicht Produkt Discount Farbe',
      tableOrderProductAdvanced: 'Bestellübersicht Produktdetails Farbe',
      tableOrderProductAdvancedButton: 'Bestellübersicht Produktdetail Aktive Buttons Hintergrund Farbe',
      tableOrderProductAdvancedButtonTitle: 'Bestellübersicht Produktdetails Aktive Buttons Titel Farbe',
      tableOrderProductAdvancedButtonInactive: 'Bestellübersicht Produktdetails Inaktive Buttons Hintergrund Farbe',
      tableOrderProductAdvancedButtonInactiveTitle: 'Bestellübersicht Produktdetails Inaktive Buttons Titel Farbe',
      tableOrderProductAdvancedButtonSeparator: 'Bestellübersicht Produktdetails Buttons Separator Farbe',
      tableOrderTotalSeparator: 'Bestellübersicht Total Separator Farbe',
      tableOrderTotal: 'Bestellübersicht Total Farbe',
      tableOrderTotalAmount: 'Bestellübersicht Total Preis Farbe',
      tableOrderCredit: 'Bestellübersicht Guthaben Farbe',
      tableOrderCreditAmount: 'Bestellübersicht Guthaben Menge Farbe',
      tableOrderPreorderBackground: 'Bestellübersicht Vorbestellung Hintergrund Farbe',
      tableOrderPreorderText: 'Bestellübersicht Vorbestellung Text Farbe',
      tableOrderSplitBackground: 'Bestellübersicht Split Hintergrund Farbe',
      tableOrderSplitText: 'Bestellübersicht Split Text Farbe',
      paymentAmountBackground: 'Zahlung Betrag-Box Hintergrund Farbe',
      paymentAmountTitle: 'Zahlung Betrag-Box Titel Farbe',
      paymentAmountSumm: 'Zahlung Summe Farbe',
      paymentAmountGiven: 'Zahlung Übergeben Farbe',
      paymentAmountGivenBorder: 'Zahlung Übergeben Border Farbe',
      paymentAmountReturn: 'Zahlung Rückgeld Farbe',
      paymentAmountTip: 'Zahlung Trinkgeld Farbe',
      paymentHeaderBackground: 'Zahlung Beträge Hintergrund Farbe',
      paymentBackground: 'Zahlung Hintergrund Farbe',
      paymentNoteBorder: 'Zahlung Noten Border Farbe',
      paymentCalculatorButtonBackground: 'Zahlung Numpad Button Hintergrund Farbe',
      paymentCalculatorButtonText: 'Zahlung Numpad Button Text Farbe',
      paymentCalculatorClearBackground: 'Zahlung Numpad Clear Hintergrund Farbe',
      paymentCalculatorClearText: 'Zahlung Numpad Clear Text Farbe',
      paymentCalculatorBorder: 'Zahlung Numpad Border Farbe',
    },
    label: 'Kassen-Thema|Kasse-Themen',
    labelWithArticle: 'das Kassen-Thema|die Kassen-Themen',
  },
  scale: {
    label: 'Waage|Waagen',
    labelWithArticle: 'die Waage|die Waagen',
  },
  paymentTerminal: {
    label: 'Payment-Terminal|Payment-Terminals',
    labelWithArticle: 'das Payment-Terminal|die Payment-Terminals',
  },
  customerScanner: {
    label: 'Kunden-Scanner|Kunden-Scanner',
    labelWithArticle: 'der Kunden-Scanner|die Kunden-Scanner',
    supportedTypes: {
      label: 'Unterstützte Kunden-ID-Typen',
    },
    scannerType: {
      label: 'Scanner-Typ',
      10: 'Evis',
    },
  },
  shift: {
    label: 'Schicht|Schichten',
    labelWithArticle: 'die Schicht|die Schichten',
    numberOfOrders: 'Anzahl Verkäufe',
    totalRevenue: 'Total Umsatz',
    totalTip: 'Total Trinkgeld',
    totalVat: 'Total MWST',
  },
  order: {
    label: 'Beleg|Belege',
    orderTime: 'Zeit',
    total: 'Total',
    invoiceNumber: 'Rechnungsnummer',
    orderCalloutNumber: 'Ausrufnummer',
    roundingAmount: 'Rundungsdifferenz',
  },
  lineItem: {
    label: 'Position|Positionen',
    description: 'Position',
    unitPrice: 'Stückpreis',
    amount: 'Anzahl',
    lineItemDiscounts: 'Rabatte',
    totalPrice: 'Total',
    vatRate: 'MWST-Satz',
  },
  orderPayment: {
    label: 'Zahlung|Zahlungen',
    paymentMethod: 'Zahlungsmethode',
    paidAmount: 'Betrag',
    consumerDetails: 'Details Konsument',
    merchantDetails: 'Details Händler',
    paymentMethods: {
      10: 'EFT',
      20: 'Bar',
      30: 'Guthaben',
    },
  },
  store: {
    all: 'Alle Filialen',
    label: 'Filiale|Filialen',
    labelWithArticle: 'die Filiale|die Filialen',
    pin: { label: 'PIN|PINs' },
    adminPin: { label: 'Admin PIN|Admin PINs' },
    vatUid: { label: 'MWST-ID|MWST-IDs' },
    receiptTopText: { label: 'Quittung Text oben|Quittung Texte oben' },
    receiptBottomText: { label: 'Quittung Text unten|Quittung Texte unten' },
    noSyncStartTime: { label: 'Keine automatische Synchronisierung von' },
    noSyncEndTime: { label: 'Keine automatische Synchronisierung bis' },
  },
  storeLayout: {
    label: 'Tisch-Layout|Tisch-Layouts',
    labelWithArticle: 'das Tisch-Layout|die Tisch-Layouts',
    tableAmount: 'Anzahl Tische',
    action: {
      editArrangement: 'Tischplan bearbeiten',
    },
    arrangement: {
      title: 'Tischplan {name}',
    },
  },
  subProductGroup: {
    addSubProductGroupProduct: 'Unterprodukt hinzufügen',
    label: 'Unterprodukt-Gruppe|Unterprodukt-Gruppen',
    labelWithArticle: 'die Unterprodukt-Gruppe|die Unterprodukt-Gruppen',
    maxChoices: { label: 'Maximale Menge|Maximale Mengen' },
    minChoices: { label: 'Mindestmenge|Mindestmengen' },
    showInPopup: { label: 'In Popup anzeigen' },
    products: { label: 'Verwendet in' },
    subProductGroupProduct: {
      label: 'Enthaltenes Produkt|Enthaltene Produkte',
      maxAmount: 'Maximalmenge',
      minAmount: 'Mindestmenge',
      unit: 'Einheit',
    },
  },
  table: {
    batch: {
      createTitle: 'Mehrere Tische',
    },
    label: 'Tisch|Tische',
    labelWithArticle: 'der Tisch|die Tische',
    number: {
      label: 'Tischnummer|Tischnummern',
      labelShort: 'Nummer',
      start: 'Tischnummer Start',
    },
    name: {
      label: 'Tischname',
    },
    allowTableOrders: {
      label: 'Bestellungen auf Tisch parkieren',
    },
    shape: {
      label: 'Form',
      SHAPE_RECTANGULAR: 'Rechteckig',
      SHAPE_ROUND: 'Rund',
    },
    vatRateType: {
      label: 'Typ',
      VAT_RATE_TYPE_INHOUSE: 'In-House',
      VAT_RATE_TYPE_TO_GO: 'To-Go',
      SPECIAL_TYPE_REPRINT_LAST_TICKET: 'Letzten Beleg drucken',
      SPECIAL_TYPE_OPEN_DRAWER: 'Kasse öffnen',
      SPECIAL_TYPE_NEW_ORDER: 'Neue Bestellung',
    },
  },
  theme: {
    color: {
      alpha: 'Alpha',
      label: 'Farbe',
      withAlpha: 'mit Alpha',
    },
    label: 'Thema|Themen',
  },
  user: {
    email: {
      label: 'E-Mail-Addresse|E-Mail-Addressen',
    },
    label: 'Benutzer|Benutzer',
    labelWithArticle: 'der Benutzer|die Benutzer',
    password: {
      label: 'Passwort|Passwörter',
    },
    role: {
      label: 'Rolle|Rollen',
      ROLE_ADMIN: 'Administrator',
      ROLE_SUPER_ADMIN: 'Superadministrator',
      ROLE_USER: 'User',
      ROLE_REGISTER: 'Kasse',
      ROLE_STATISTICS: 'Statistiken',
      ROLE_PRODUCT_MANAGER: 'Produktmanager',
    },
  },
  vatRate: {
    inherit: 'Erben',
    inherited: '{0} ({1}%) geerbt',
    inHouse: {
      label: 'In House-MwSt.',
    },
    label: 'MwSt.-Satz|MwSt.-Sätze',
    labelWithArticle: 'der MwSt.-Satz|die MwSt.-Sätze',
    toGo: {
      label: 'To Go-MwSt.',
    },
  },
  revenueStatistics: {
    label: 'Umsatz-Plot',
    startDate: 'Start',
    endDate: 'Ende',
    groupFormat: {
      label: 'Level',
      second: 'pro Sekunde',
      minute: 'pro Minute',
      hour: 'pro Stunde',
      day: 'pro Tag',
      week: 'pro Woche',
      month: 'pro Monat',
      year: 'pro Jahr',
    },
  },
  discountStatistics: {
    label: 'Rabatt-Plot',
  },
  overallStatistics: {
    label: 'Umsatzstatistik|Umsatzstatistiken',
    fetch: 'Laden',
    rawData: 'Rohdaten',
    netResults: 'Umsatz (exkl. Mwst.)',
    grossResults: 'Umsatz (inkl. Mwst.)',
    total: 'Total',
    vat: 'Mwst.',
    undiscountedRevenue: 'Umsatz ohne Rabatt',
    discountedRevenue: 'Umsatz mit Rabatt',
    discounts: 'Rabatte',
    refunds: 'Stornos',
  },
  productStatistics: {
    netResults: 'Umsatz (exkl. Mwst.)',
    label: 'Produktstatistik|Produktstatistiken',
    fetch: 'Laden',
    revenue: 'Umsatz',
    revenueTotal: 'Total',
    numProducts: 'Anzahl',
    undiscountedRevenue: 'Umsatz (unrabattiert)',
    discountTotal: 'Total',
    product: 'Produkt',
  },
  requestLog: {
    label: 'Request Log',
    createdAt: {
      label: 'Uhrzeit',
    },
    time: {
      label: 'Laufzeit',
    },
    code: {
      label: 'Code',
    },
    url: {
      label: 'URL',
    },
    method: {
      label: 'HTTP Methode',
    },
    requestBody: {
      label: 'Request',
    },
    responseBody: {
      label: 'Response',
    },
  },
};
